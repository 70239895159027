











import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';

@Component({
  components: { SygniRectButton }
})
export default class MarketplaceModule extends Vue {
  async beforeMount() {
    try {
      this.$store.commit('investors/setPropagate', false);
      await this.$store.dispatch('campaigns/getLeadForm');
    } catch(e) {
      e;
    }
  }
}
